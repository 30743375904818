import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ebe6904"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("img", {
        src: _ctx.Logo,
        class: "sidebar-logo"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("main", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.get(_ctx.formattedEvent, 'eventSections', []), (section) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "main-item",
          key: section.id
        }, [
          _createElementVNode("img", {
            src: _ctx.getSectionPhoto(section)
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("footer", null, [
      _createElementVNode("img", {
        src: _ctx.Logo,
        class: "sidebar-logo"
      }, null, 8, _hoisted_4)
    ])
  ]))
}