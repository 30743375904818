
import { useEvent } from '@/composables';
import { EventSection } from '@/interfaces';
import { get, isEmpty } from 'lodash';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const Logo = 'https://www.modeltv.com/_nuxt/img/logo-yellow.39823f1.svg';
    const id = useRoute().params.id as string;

    const event = ref<any>();
    const { data } = useEvent({ id });

    event.value = JSON.parse(localStorage.getItem(`eventPreview${id}`));

    const formattedEvent = computed(() => isEmpty(event.value) ? data.value : event.value);

    const getSectionPhoto = (section: EventSection) => {
      if (window.innerWidth < 768) {
        return get(section, 'mobilePhoto.path') ? get(section, 'mobilePhoto.path') : get(section, 'photo.path');
      }
      return get(section, 'photo.path');
    };

    return {
      get,
      Logo,
      formattedEvent,
      getSectionPhoto
    };
  }
});
